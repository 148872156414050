import React, { useState } from 'react';
import ReactPlayer from 'react-player';

const VideoGallery = () => {
  const videos = [
    { id: 1, url: 'https://www.youtube.com/watch?v=ax2-IX7tDhc',         thumbnail: "https://i.ytimg.com/vi/FDxgJxmRUd4/hqdefault.jpg?sqp=-oaymwEbCKgBEF5IVfKriqkDDggBFQAAiEIYAXABwAEG&rs=AOn4CLAjA7n9Nz98ikCq151yHG40V-OWHg"
, description: 'Descripción del video 1' },
    { id: 2, url: 'https://www.youtube.com/watch?v=0AkuEJqPXQs',         thumbnail: "https://i.ytimg.com/vi/FDxgJxmRUd4/hqdefault.jpg?sqp=-oaymwEbCKgBEF5IVfKriqkDDggBFQAAiEIYAXABwAEG&rs=AOn4CLAjA7n9Nz98ikCq151yHG40V-OWHg"
, description: 'Descripción del video 2' },
    { id: 3, url: 'https://www.youtube.com/watch?v=0AkuEJqPXQs',         thumbnail: "https://i.ytimg.com/vi/FDxgJxmRUd4/hqdefault.jpg?sqp=-oaymwEbCKgBEF5IVfKriqkDDggBFQAAiEIYAXABwAEG&rs=AOn4CLAjA7n9Nz98ikCq151yHG40V-OWHg"
, description: 'Descripción del video 3' },
    // Agregar más videos aquí
  ];

  const [selectedVideo, setSelectedVideo] = useState(videos[0].url);

  const handleVideoSelect = (url) => {
    setSelectedVideo(url);
  };

  return (
    <div id="videos" className="video-gallery-container">
        <h1>Videos</h1>
      <div className="video-player">
        <ReactPlayer url={selectedVideo}/>
      </div>
      <h2>Más videos</h2>
      <div className="video-list">
        {videos.map((video) => (
          <div key={video.id} onClick={() => handleVideoSelect(video.url)}>
            <img src={video.thumbnail} alt="Miniatura de video" />
            <p>{video.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default VideoGallery;
