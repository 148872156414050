import Red from './Red'
import { SocialIcon } from 'react-social-icons'
import { Grid, Row, Col } from 'react-flexbox-grid';
import { GoLocation } from 'react-icons/go'
import { FaWhatsapp, FaBirthdayCake } from 'react-icons/fa'
import { CgMail } from 'react-icons/cg'
import { TfiAgenda } from 'react-icons/tfi'

const iconStyle = {bgColor:"#1e2326", fgColor:"#ffffff", style:{ height: 40, width: 40}};

export default function Contacto(props) {  
  return (
    <>
    <section id="contacto" class="contacto">
    <div class="contenido-seccion">
        <h2>CONTACTO</h2>
        <div class="fila">
            <Grid className="col">
                    <h3>Datos Personales </h3>
                    
                      <ul><FaBirthdayCake/> 01-01-1991 </ul>
                      <ul><FaWhatsapp/> +34 666555444 </ul>
                      <ul><CgMail/> <a href="mailto:pau@gmail.com">pau@gmail.com</a></ul>
                      <ul><GoLocation/> Madrid </ul>
                      <ul><TfiAgenda/> <a href={"https://thisisbendita.com"} target="_blank"> Bendita Profesión</a></ul> 
                    
            </Grid>
            <div class="col">
            <h3>Me puedes encontrar en:</h3>
            <div class="redes">
            <Col>
                <SocialIcon url="https://www.instagram.com/pausola/" {...iconStyle} target="_blank">
                </SocialIcon>
              
                <SocialIcon url="https://youtube.com/@pausolalorente7506" {...iconStyle} target="_blank">
                </SocialIcon>

            </Col>
        </div>
            </div>
        </div>
    </div>
</section>
</>
  );
}

