import image from '../img/pau1.jpg'
import Red from './Red'
import { SocialIcon } from 'react-social-icons'
const iconStyle = {bgColor:"#A7B3A1", fgColor:"#ffffff", style:{ height: 40, width: 40}};

export default function Inicio(props) {
      return (
        <section id="inicio" className="inicio">
          <div className="contenido-banner">
            <img src={image} alt="" />
            
            <div className="redes">
              <ul>
                <SocialIcon url="https://www.instagram.com/pausola/" {...iconStyle} target="_blank" />
                <SocialIcon url="https://youtube.com/@pausolalorente7506" {...iconStyle} target="_blank" />
            </ul>
            </div>
          </div>
        </section>
      );
    }