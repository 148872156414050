import { useState } from 'react';
import Navegacion from './Navegacion';

export default function Header(props) {
  const [responsiveMenuVisible, setResponsiveMenuVisible] = useState(false);
  const [menuOptionClicked, setMenuOptionClicked] = useState(false);

  const handleOptionClick = () => {
    setResponsiveMenuVisible(false);
    setMenuOptionClicked(true);
  }

  const handleMenuClose = () => {
    setResponsiveMenuVisible(false);
    setMenuOptionClicked(false);
  }

  return (
    <header id="header">
      
      
        <nav id="nav" className={(responsiveMenuVisible && !menuOptionClicked) ? 'responsive' : ''}>
                  
          <ul className="header" onClick={handleMenuClose}>
          
          <Navegacion onClick={handleOptionClick}>Inicio</Navegacion>
          <Navegacion onClick={handleOptionClick}>Trabajos</Navegacion>
          <Navegacion onClick={handleOptionClick}>Formacion</Navegacion>
          <li className="headerPauContainer">
            <a className="headerPau" href="#">Pau Sola</a>
            <br />
            <span className="actor">Actor</span>
          </li>
          <Navegacion onClick={handleOptionClick}>Videos</Navegacion>
          <Navegacion onClick={handleOptionClick}>Fotos</Navegacion>
          <Navegacion onClick={handleOptionClick}>Contacto</Navegacion>
          </ul>
        </nav>
        <div className="nav-responsive" onClick={() => setResponsiveMenuVisible(!responsiveMenuVisible)}>
          <i className="fa-solid fa-bars"></i>
        </div>
    </header>
  );
}
