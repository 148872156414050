import React, { useRef } from "react";
import ReactPlayer from 'react-player'
const videos  = require('../videos.json')

export default function Trabajos() {
  return (
    <>
  <section id="videos" class='videos'>
        <h2>Videos</h2>
          <div class="grid-container">
          {videos.videos.map((video) => (
            <div >
            <h1>{video.descripción}</h1>
            <ReactPlayer url={video.url} width="100%" height="60%"/>
            </div>
          ))}
      </div>
  </section>
  </>
  );
}