import React, { useState, useEffect } from 'react';
import 'react-vertical-timeline-component/style.min.css';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';

const trabajosRealizados  = require('../trabajos.json')

export default function formacion(props) {

  const iconStyle = { bgColor:"#1e2326", fgColor:"#ffffff", style:{ height: 40, width: 40 } };

  return (

    <section id="formacion" class="formacion">
    <div class="contenido-seccion">
    <h2>Formación</h2>
   <Timeline position="alternate">
   {trabajosRealizados.formacion.map((trabajo) => (
    <TimelineItem>
      <TimelineOppositeContent>
        {trabajo.anyo}
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineDot />
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent>
        {trabajo.titulo} {trabajo.descripcion ? `- ${trabajo.descripcion}` : ''}
      </TimelineContent>
    </TimelineItem>
   ))}
  </Timeline>
  </div>
  </section>
  );
}