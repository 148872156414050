import React, { useRef } from 'react';

export default function Navegacion(props) {
  const handleClickScroll = () => {
    const element = document.getElementById(props.children.toLowerCase());
    if (element) {
      element.scrollIntoView(
        {
           behavior: 'smooth'
        });
    }
  };
  
  return (
    <li><a className={"secciones"} onClick={handleClickScroll}>{props.children}</a></li>
  );
}