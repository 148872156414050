//const fPau = document.getElementById("fpau");
const fotos = importAll(require.context('../img'));
const fotosImportadas = prueba(fotos);


export default function Fotos(props) {
  return (
    <section ref={fotos} id="fotos" class="portfolio">
        <div class="contenido-seccion">
            <h2>Fotos</h2>
            <div class="grid-container">
            <>
            {fotosImportadas}
            </>
            </div>
        </div>
    </section>
  );
}

function prueba(fotos){
  return(
  fotos.map(foto => {
    return <a href={foto} target="_blank"><img src={foto}/></a>
  })

  )

}

function importAll(r) {
  return r.keys().map(r);
}

