import React, { useRef } from 'react';
import { AiOutlineArrowUp } from 'react-icons/ai'


export default function Navegacion(props) { 
 
  const handleClickScroll = () => {
    const element = document.getElementById('header');
    if (element) {
      element.scrollIntoView(
      {
        behavior: "smooth"
      });
  }
};
  
  
  return (
    <>
    <a onClick={handleClickScroll} className={"back-to-top-button"}><AiOutlineArrowUp/> </a>
     </>
  );
}
