import logo from './logo.svg';
import React, { useRef } from "react";
import './App.css';
import Header from './components/Header'
import Inicio from './components/Inicio'
import Trabajos from './components/Trabajos'
import Formacion from './components/Formacion';
import Fotos from './components/Fotos';
import Videos from './components/Videos';
import Contacto from './components/Contacto';
import ScrollToTop from './components/ScrollToTop';
import Bottom from './components/Bottom';
import VideoGallery from './components/videoPrueba';

function App() {

  return (
    <div class="pagina-entera">
      
      <Header></Header>
      <Inicio></Inicio>
      <div class="fondoNegro">
      <Trabajos></Trabajos>
      <Formacion></Formacion>
      {/* <Videos></Videos> */}
      
      <VideoGallery></VideoGallery>
      <Fotos></Fotos>
      <Contacto></Contacto>
      <ScrollToTop></ScrollToTop>
    </div>
    </div>
    
  );
}



export default App;
