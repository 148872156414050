import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import React, { useState, useEffect } from 'react';
import 'react-vertical-timeline-component/style.min.css';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';

const trabajosRealizados  = require('../trabajos.json')

export default function Trabajos(props) {
  const [positionRight, setPositionRight] = useState("right");
  const [positionLeft, setPositionLeft] = useState("left");

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 760) {
        setPositionRight("alternate");
      } else {
        setPositionRight("right");
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 760) {
        setPositionLeft("alternate");
      } else {
        setPositionLeft("left");
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const iconStyle = { bgColor:"#aaaaa6", fgColor:"#ffffff", style:{ height: 40, width: 40 } };

  return (

    <section id="trabajos" class="formacion">
    <div class="contenido-seccion">
    <h2>Trabajos</h2>
   <h1>Audiovisual</h1>
   <Timeline className={"timeline-audiovisual"} position={positionLeft}>
   {trabajosRealizados.audiovisual.map((trabajo) => (
    <TimelineItem>
      <TimelineOppositeContent color="#A7B3A1">
        {trabajo.anyo}
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineDot />
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent>
        {trabajo.titulo} {trabajo.descripcion ? `- ${trabajo.descripcion}` : ''}
      </TimelineContent>

    </TimelineItem>
   ))}
  </Timeline>

  <h1>Teatro</h1>
  <Timeline className={"timeline-teatro"} position={positionRight}>
   {trabajosRealizados.teatro.map((trabajo) => (
    <TimelineItem>
      <TimelineOppositeContent color="#A7B3A1">
        {trabajo.anyo}
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineDot />
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent>
        {trabajo.titulo} {trabajo.descripcion ? `- ${trabajo.descripcion}` : ''}
      </TimelineContent>

    </TimelineItem>
   ))}
  </Timeline>

  <h1>Publicidad</h1>
   <Timeline className={"timeline-publicidad"} position={positionLeft}>
   {trabajosRealizados.Publicidad.map((trabajo) => (
    <TimelineItem>
      <TimelineOppositeContent color="#A7B3A1">
        {trabajo.anyo}
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineDot />
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent>
        {trabajo.titulo} {trabajo.descripcion ? `- ${trabajo.descripcion}` : ''}
      </TimelineContent>

    </TimelineItem>
   ))}
  </Timeline>


  </div>
  </section>
  );
}